import bridge from "./bridge.png";
import "./App.css";
import React from "react";
import firebase from "firebase";
import cabin from "./images/cabin.png";
import wake from "./images/wake.png";
import kayak from "./images/kayak.png";
import pontoon from "./images/pontoon.png";
import bass from "./images/fishing.png";
import pwc from "./images/pwc.png";
import speed from "./images/speed.png";
import bowrider from "./images/bowrider.png";

class App extends React.Component {
  constructor(props) {
    super(props);

    const boatTypes = [
      {
        name: "cabin",
        averageMaxHeight: 11,
        canFit: false,
        imageUrl: cabin,
      },
      {
        name: "wake",
        averageMaxHeight: 8,
        canFit: false,
        imageUrl: wake,
      },
      {
        name: "kayak",
        averageMaxHeight: 3,
        canFit: false,
        imageUrl: kayak,
      },
      {
        name: "pontoon",
        averageMaxHeight: 6,
        canFit: false,
        imageUrl: pontoon,
      },
      {
        name: "bass",
        averageMaxHeight: 4,
        canFit: false,
        imageUrl: bass,
      },
      {
        name: "pwc",
        averageMaxHeight: 5,
        canFit: false,
        imageUrl: pwc,
      },
      // {
      //   name: "speed",
      //   averageMaxHeight: 6,
      //   canFit: false,
      //   imageUrl: speed,
      // },
      {
        name: "bowrider",
        averageMaxHeight: 7,
        canFit: false,
        imageUrl: bowrider,
      },
    ];

    this.state = {
      lakeLevel: null,
      boatTypes: boatTypes,
    };
    this.componentDidMount.bind(this);
  }

  sortByMaxHeight = (a, b) => {
    if (a.averageMaxHeight < b.averageMaxHeight) {
      return -1;
    }
    if (a.averageMaxHeight > b.averageMaxHeight) {
      return 1;
    }
    return 0;
  };

  updateLakeLevel = (data) => {
    const bottomOfBridge = 1134.0;
    let lakeLevel = (
      bottomOfBridge - parseFloat(data.lakeLevel.replace(",", ""))
    ).toFixed(2);

    let boats = this.state.boatTypes;
    boats.forEach((boat) => {
      if (boat.averageMaxHeight < lakeLevel) {
        boat.canFit = true;
      }
    });
    boats.sort(this.sortByMaxHeight);

    this.setState({
      lakeLevel: lakeLevel,
      boatTypes: boats,
    });
    firebase.analytics().logEvent("calculate_lakelevel", {});
  };

  componentDidMount = () => {
    const firebaseConfig = {
      apiKey: "AIzaSyDCYNCiovZFOnCY0i3hCeujXlwdF06biaI",
      authDomain: "canigetunderthebridge.firebaseapp.com",
      projectId: "canigetunderthebridge",
      storageBucket: "canigetunderthebridge.appspot.com",
      messagingSenderId: "293421173418",
      appId: "1:293421173418:web:99037bba470d6466169ed3",
      measurementId: "G-B44YD80P7D",
    };
    // Initialize Firebase
    firebase.initializeApp(firebaseConfig);
    firebase.analytics();

    const lakeLevel = fetch(
      "https://us-central1-canigetunderthebridge.cloudfunctions.net/lakeLevel"
    );
    lakeLevel
      .then(function (response) {
        return response.json();
      })
      .then(this.updateLakeLevel);

    // const mockedData = { lakeLevel: "1128.48" };
    // this.updateLakeLevel(mockedData);
  };

  getIcon = (boat) => {
    return (
      <div className="Icon-container">
        <img src={boat.imageUrl} alt="logo" className="Boat-icon" />
        {boat.canFit && <p className="check">✅</p>}
        {!boat.canFit && <p className="check">🚫</p>}
      </div>
    );
  };

  render() {
    return (
      <div className="App">
        <header className="App-header">
          {/* <img src={bridge} className="App-logo" alt="logo" /> */}
          {this.state.lakeLevel && (
            <div>
              <div className="boats">
                {this.state.boatTypes.map((element) => {
                  return this.getIcon(element);
                })}
              </div>
              <p>
                Current clearance is about{" "}
                <h1>
                  <b>{this.state.lakeLevel} feet</b>
                </h1>
              </p>
            </div>
          )}
          {!this.state.lakeLevel && <p>asking Facebook real quick ...</p>}
        </header>
        <footer className="App-footer">
          This data is an approximation and may not always be accurate. Always
          use caution when determinig if you can fit under the bridge.
          canigetunderthebridge.com is not responsible for any damage.
        </footer>
      </div>
    );
  }
}

export default App;
